<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        报名查询
      </template>
      <template #input>
        <a-input placeholder="请输入姓名" v-model="name" />
        <a-input placeholder="请输入手机号" v-model="mobile" />
        <a-input placeholder="请输入身份证号" v-model="idcard" />
        <a-select mode="multiple" placeholder="请选择身份" v-model="userType" showArrow>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="0">普通用户</a-select-option>
          <a-select-option :value="1">家长</a-select-option>
          <a-select-option :value="2">从业者</a-select-option>
          <!-- <a-select-option :value="3">机构</a-select-option> -->
        </a-select>
        <a-select mode="multiple" placeholder="请选择报名人员来源" v-model="tripartUser">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">华夏</a-select-option>
          <a-select-option value="2">恩启</a-select-option>
          <a-select-option value="3">ALSOLIFE</a-select-option>
        </a-select>
        <a-range-picker  @change="onTimeChange" show-time format="YYYY-MM-DD HH:mm:ss" :placeholder="['考试开始时间', '考试结束时间']" v-model="clear"/>
        <a-input placeholder="请输入考试名称" v-model="examName"/>
        <a-select placeholder="请选择审核状态" allowClear v-model="auditStatus">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">待审核</a-select-option>
          <a-select-option value="2">已审核</a-select-option>
          <a-select-option value="3">已驳回</a-select-option>
        </a-select>
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch()">搜索</a-button>
        <a-button class="btn all_boder_btn" @click="onEmpty()">重置</a-button>
        <a-button class="all_boder_btn" @click="isExportShow=true">导出</a-button>
      </template>
    </HeaderBox>

    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.signup_user_id"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
        </template>
    </a-table>
    </div>

    <!-- 导出弹窗 -->
    <Derive ref="export"
      :visible="isExportShow"
      :handleCancel="modelCancel"
      @onexportList="onexportList()"
      :dataList="[
        {
          title: '报名信息',
          options: exportList,
        },
      ]"
    />
    <!-- <a-modal v-model="isExportShow" title="导出数据" okText="确认导出" width="850px">
      <div class="modalDiv">
        <p>请选择表格中需要的字段内容：</p>
        <div class="code-item" v-for="(item,index) in exportList" :key="index">
          <span v-for="(subItem,i) in item.children" :key="i + 'sub'"><a-checkbox :defaultChecked="true">{{subItem}}</a-checkbox></span>
        </div>
        <span></span>
      </div>
    </a-modal> -->
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "mobile"
  },
  {
    title: "身份证号",
    align:'center',
    dataIndex: "idcard"
  },
  {
    title: "考试名称",
    align:'center',
    dataIndex: "examName"
  },
   {
    title: "考试日期",
    align:'center',
    dataIndex: "examDate"
  },
  {
    title: "考试场次",
    align:'center',
    dataIndex: "examDateTime"
  },
  {
    title: "报名时间",
    align:'center',
    dataIndex: "signupStartDate"
  },
  {
    title: "学习进度",
    align:'center',
    dataIndex: "studyStatus"
  },
  {
    title: "补考次数",
    align:'center',
    dataIndex: "makeNum"
  },
  {
    title: "审核状态",
    align:'center',
    dataIndex: "auditStatusText",
  },
];
import HeaderBox from '@/components/HeaderBox.vue'
import Derive from "@/components/derive.vue"; // 导出组件

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Derive },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      name: "",
      mobile: "",
      idcard: "",
      userType: undefined,
      tripartUser: undefined,
      startDate: '',
      endDate: '',
      examName: '',
      auditStatus: undefined,
      clear: undefined,
      examList: [],
      columns, // 表头
      tableData:[], // 列表数据
      loading:false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      isExportShow:false, // 导出弹窗,
      certifItem: {},// 操作对象
      exportList: [
        { name: "用户姓名", value: "name", checked: true, disabled: false },
        { name: "手机号", value: "mobile", checked: true, disabled: false },
        { name: "身份证", value: "idcard", checked: true, disabled: false },
        { name: "性别", value: "sex", checked: true, disabled: false },
        { name: "学历", value: "education", checked: true, disabled: false },
        { name: "职务", value: "post", checked: true, disabled: false },
        { name: "生日", value: "birthday", checked: true, disabled: false, },
        { name: "工作单位", value: "workUnit", checked: true, disabled: false },
        { name: "所在地", value: "address", checked: true, disabled: false },
        { name: "用户名", value: "userName", checked: true, disabled: false },
        { name: "审核状态", value: "auditStatusText", checked: true, disabled: false },
        { name: "课程", value: "courseName", checked: true, disabled: false },
        { name: "考试名称", value: "examName", checked: true, disabled: false },
        { name: "考试日期", value: "examDate", checked: true, disabled: false, },
        { name: "考试场次", value: "examDateTime", checked: true, disabled: false },
        { name: "身份", value: "userTypeText", checked: true, disabled: false, },
        { name: "是否第三方考试人员", value: "isTripartUser", checked: true, disabled: false, },
        { name: "三方名称", value: "tripartUserText", checked: true, disabled: false, },
        { name: "补考次数", value: "makeNum", checked: true, disabled: false, },
      ],
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    // 选择时间
    onTimeChange(date, dateString){
      this.startDate = dateString[0];  // 开始日期
      this.endDate = dateString[1];  // 结束日期
    },
    // 重置
    onEmpty () {
      this.name = "";
      this.mobile = "";
      this.idcard = "";
      this.userType = undefined;
      this.tripartUser = undefined;
      this.startDate = '';
      this.endDate = '';
      this.examName = '';
      this.auditStatus = undefined;
      this.pageNumber = 1;
      this.clear = undefined;
      this.getList();
    },
    // 搜索
    onSearch() {
      this.pageNumber = 1;
      this.getList();
    },
    // 详情
    goPage(){
      this.$router.push({ path: "/admin/Search/UserDetail" });
    },
    // getExam(){
    //   this.$ajax({
    //     url: "/hxclass-management/exam/config/exam/tab/list",
    //   }).then((res) => {
    //     if (res.code == 200 && res.success) {
    //       this.examList = res.data;
    //     }
    //   });
    // },
    modelCancel() {
      this.isExportShow = false;
      this.$refs.export.checkedList(); 
    },
    // 导出功能
    onexportList() {
      // console.log(this.exportList);
      let filter = this.exportList.filter((item) => {
        return item.checked;
      });
      let arr = filter?.map((item, index) => {
        if (item.checked) {
          return {
            name: item.name,
            value: item.value,
          };
        }
      });
      window.open(this.$config.target+"/hxclass-management/exam/signup/signup-query-export?columnJson=" + encodeURIComponent(JSON.stringify(arr)) + "&auditStatus=" + encodeURIComponent(!this.auditStatus?'': this.auditStatus)  + "&examName=" + encodeURIComponent(this?.examName) + "&name=" + encodeURIComponent(this?.name) + "&mobile=" + encodeURIComponent(this?.mobile) + "&idcard=" + encodeURIComponent(this?.idcard) + "&userType=" + encodeURIComponent(this.userType?String(this.userType):'') + "&startDate=" + encodeURIComponent(this?.startDate) + "&endDate=" + encodeURIComponent(this?.endDate) + "&tripartUser=" + encodeURIComponent(this.tripartUser?String(this.tripartUser):''));
    },
    getList(){
      let userType = [];
      let userTypeStr = '';
      let arry = [
        [1, 4, 5, 7],
        [2, 4, 6, 7],
        [3, 5, 6, 7],
      ];
      // console.log(this.userType);
      if (this.userType != null) {
        this.userType.forEach((element) => {
          if (0 < element) {
            userType.push(...arry[element - 1]);
          } else {
            userType.push(0);
          }
        });
        userTypeStr = Array.from(new Set(userType)).toString();
      }
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/exam/signup/signup-query",
        params: {
          name: this.name,
          mobile: this.mobile,
          idcard: this.idcard,
          userType: userTypeStr,
          tripartUser: String(this.tripartUser),
          startDate: this.startDate,
          endDate: this.endDate,
          examName: this.examName,
          auditStatus: this.auditStatus,
          current: this.pageNumber,
          size: this.pageSize,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // this.getExam();
    this.getList();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
 /deep/.right-box{
  padding-bottom: 5px !important;
}
.modalDiv{
  >p{
    font-weight: 500;
    color: #333333;
  }
  .code-item{
    >span{
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}
</style>
